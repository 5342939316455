@import './variables';

@import './vendor/bootstrap/bootstrap-4.4.1/scss/bootstrap-grid.scss';
@import './vendor/bootstrap/bootstrap-4.4.1/scss/utilities/embed';

@import './utility/all_utility';

body {
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
}

@import './partials/all_partials';

@import './footer';

@import './utility/loader';

