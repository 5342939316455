.prev_next_element {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;

  .prev {
    position: relative;
    padding-left: 20px;
    text-decoration: none;
    
    &::before {
      content: "<";
      position: absolute;
      left: 0;
      top: 1px;
      transform: scaleY(1.8);
      transition: 0.3s ease;
    }

    &:hover {
      &::before {
        transform: translateX(-5px) scaleY(1.8);
      }
    }
  }

  .next {
    position: relative;
    padding-right: 20px;
    text-decoration: none;
    
    &::before {
      content: ">";
      position: absolute;
      right: 0;
      top: 1px;
      transform: scaleY(1.8);
      transition: 0.3s ease;
    }
    &:hover {
      &::before {
        transform: translateX(5px) scaleY(1.8);
      }
    }
  }
}