/** 
 * EDIT ACTIVATION
 * Naar bedenden vanwege menu
 **/

 .edit-activation {
  top: 100px;
  right: 38px;
  
  button {
    cursor: pointer;
  }
}

#page-buttons {
  button {
    cursor: pointer;
  }
}