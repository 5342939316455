.button {
  @include button;
}

.button--primary {
  @include button($color-primary, #fff);
}

.button--ghost {
  @include button--ghost();
}