footer {
  background: $footer-background;
  padding: 3rem 0;
  color: $footer-text-color;
  
  .footer_menu {
    border-bottom: 2px solid $color-secondary;

    nav {
      .nav_list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media(max-width: 768px){
          flex-direction: column;
        }
  
        li {
          padding: 0.5rem;

          a {
            color: $footer-text-color;
            text-decoration: none;
            text-transform: uppercase;
            opacity: 0.7;
            font-size: 0.8rem;

            &:hover {
              text-decoration: underline;
              opacity: 1;
            }
            
            @media(max-width: 768px){
              display: block;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .footer_content {

    &__image {
      text-align: center;
      margin: 2rem 0 1rem;

      img {
        max-width: 150px;
      }
    }

    &__links {
      text-align: center;
      font-size: 0.75rem;
      opacity: 0.7;

      a {
        color: $footer-link-color;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  
}