@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,300;0,500;0,600;1,300;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html {
  font-size: 18px;
  line-height: 1.4;
  font-family: $basic-font;
  font-weight: 400;
  color: $color-text-root;

  @media(max-width: 1200px){
    font-size: 16px;
  }
  @media(max-width: 992px){
    font-size: 14px;
  }
  @media(max-width: 768px){
    font-size: 18px;
  }
  @media(max-width: 480px){
    font-size: 16px;
  }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

h6 {
  @include h6;
}

a {
  color: $color-text-root;
  text-decoration: underline;

  &:hover {
    color: $color-text-root-accent;
  }
}