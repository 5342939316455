html {
  &::after {
    content: "";
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    background-image: url("/images/default/svg/logo/logo--gold.svg");
    background-size: 200px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0;
    transition: 0.3s ease;
    pointer-events: none;
    z-index: 1000000;
  }

  &.intro_animation {
    overflow-y: scroll;

    &::after {
      opacity: 1;
      pointer-events: initial;
    }

    body {
      max-height: 99vh;
      max-width: 99vw;
      overflow: hidden;
    }
  }
  &.exit_animation {
    &::after {
      opacity: 1;
      pointer-events: initial;
    }
  }
}