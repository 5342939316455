a.ca span,
a.coverall span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%; 
}

.cf:after, 
.clearfix:after {
  content: "";
  display: table;
  clear: both; 
}

@media(max-width: 1140px){
  .edit-mode .container {
    max-width: 100%;
  }
}